import store from '@/store/store.js'

class SegmentRuleTransformer {
  constructor(query = null) {
    this.query = query
    this.queryElement = null
    this.queryRules = store.getters['IndustryReporter/segmentRules']
    this.buildQueryElement(this.query)
  }

  getSegmentRule(rule) {
    return this.queryRules.find(qr => qr.id === rule.ruleId)
  }
  getSegmentRuleId(ruleName) {
    let rule = this.queryRules.find(qr => qr.label === ruleName)
    return rule ? rule.id : ''
  }
  // getOperandRuleForTreeRule(query) {
  //   let rule = this.queryRules.find(qr => qr.label === query.parent)
  //   return rule.children.find(or => or.element_id == query.operand)
  // }
  // getChildOperandRuleForTreeRule(query) {
  //   let rule = this.queryRules
  //     .find(qr => qr.label === query.parent)
  //     .children.find(or => or.label === query.service)
  //   return rule.children.find(cor => cor.element_id == query.childOperand)
  // }
  getOperandRule(rule) {
    return this.getSegmentRule(rule).children.find(or => or.id === rule.operand)
  }

  getChildOperandRule(rule) {
    return this.getOperandRule(rule).children.find(
      cor => cor.id === rule.childOperand
    )
  }

  buildQueryElement(rule) {
    let segmentType = this.getSegmentRule(rule)

    switch (segmentType.label) {
      case 'Market':
        this.prepareGeneralPropertyElement(rule)
        break
      case 'Digital Channels':
        this.prepareDigitalChannelElement(rule)
        break
      case 'Segmentation':
        this.prepareGeneralPropertyElement(rule)
        break
      case 'Firmographics':
      case 'Performographics':
        this.prepareFirmographicElement(rule)
        break
      case 'Common Metrics (Bank/CU)':
        this.prepareMetricElement(rule, 'common_metric', 'value')
        break
      case 'Detailed Metrics':
        this.prepareMetricElement(rule, 'metrics', 'value')
        break
      case 'Common Statements (Bank/CU)':
        this.prepareFinancialStatementElement(
          rule,
          'common_financial_statements',
          'default'
        )
        break
      case 'Detailed Statements':
        this.prepareFinancialStatementElement(
          rule,
          'financial_statements',
          'value'
        )
        break
      case 'Technographics':
        this.prepareTechnographicElement(rule)
        //this.findParentElement(rule)
        break
      case 'Balance Sheet':
      case 'Deposit Details':
      case 'Credit Quality':
      case 'Bank - Balance Sheet':
      case 'Bank - Deposit Details':
      case 'Bank - Credit Quality':
      case 'CU - Balance Sheet':
      case 'CU - Deposit Details':
      case 'CU - Credit Quality':
        this.prepareFinancialStatementElement(rule, true)
        break
      case 'Income Statement':
      case 'Bank - Income Statement':
      case 'CU - Income Statement':
        this.prepareFinancialStatementElement(rule, false)
        break
      default:
        break
    }
  }
  isTreeSelectionRule(rule) {
    let segmentType = this.getSegmentRule(rule)
    return [
      'Detailed Metrics',
      'Common Metrics (Bank/CU)',
      'Detailed Statements',
      'Common Statements (Bank/CU)',
      'Technographics'
    ].includes(segmentType.label)
  }

  // findParentElement(rule) {
  //   switch (rule.parent) {
  //     case 'Retail Services':
  //     case 'Business Services':
  //     case 'Operating Solutions':
  //       this.prepareTechnographicElement(rule)
  //       break
  //     case 'Balance Sheet':
  //     case 'Deposit Details':
  //     case 'Credit Quality':
  //     case 'Income Statement':
  //       this.prepareFinancialStatementElement(
  //         rule,
  //         'common_financial_statements',
  //         'default'
  //       )
  //       break
  //     case 'Bank - Balance Sheet':
  //     case 'Bank - Deposit Details':
  //     case 'Bank - Credit Quality':
  //     case 'CU - Balance Sheet':
  //     case 'CU - Deposit Details':
  //     case 'CU - Credit Quality':
  //     case 'Bank - Income Statement':
  //     case 'CU - Income Statement':
  //       this.prepareFinancialStatementElement(
  //         rule,
  //         'financial_statements',
  //         'value'
  //       )
  //       break

  //     // this.prepareFinancialStatementElement(rule, false)
  //     // break
  //     case 'Profit':
  //     case 'Growth':
  //     case 'Risk':
  //       this.prepareMetricElement(rule, 'common_metric', 'value')
  //       break
  //     case 'CU - Profit':
  //     case 'Bank - Profit':
  //     case 'CU - Growth':
  //     case 'Bank - Growth':
  //     case 'CU - Risk':
  //     case 'Bank - Risk':
  //       this.prepareMetricElement(rule, 'metrics', 'value')
  //       break
  //     default:
  //       break
  //   }
  // }
  prepareDigitalChannelElement(rule) {
    if (rule.source && rule.source.match('bank_services.id')) {
      this.prepareTechnographicElement(rule)
    } else {
      this.prepareGeneralPropertyElement(rule)
    }
  }

  prepareTechnographicElement(rule) {
    let segmentRule = this.getSegmentRule(rule)

    // let childOperandRule = null

    // if (this.isTreeSelectionRule(rule)) {
    //   childOperandRule =
    //     section === 'digital_channels'
    //       ? this.getOperandRuleForTreeRule(rule)
    //       : this.getChildOperandRuleForTreeRule(rule)
    // } else {
    //   childOperandRule =
    //     section === 'digital_channels'
    //       ? this.getOperandRule(rule)
    //       : this.getChildOperandRule(rule)
    // }
    let property = rule.operator
      .match(/vendor|product|has service/g)[0]
      .replace(' ', '_')

    this.queryElement = {
      elementID: rule.operand,
      type: 'technographics',
      property: [property],
      section:
        segmentRule.label === 'Digital Channels' ? 'digital_channels' : ''
    }
  }

  prepareMetricElement(rule, elType, elProperty) {
    // let operandRule = this.getOperandRule(rule)
    // if (this.isTreeSelectionRule(rule)) {
    //   operandRule = this.getOperandRuleForTreeRule(rule)
    // }
    this.queryElement = {
      elementID: rule.operand,
      type: elType,
      property: elProperty
    }
  }

  prepareFirmographicElement(rule) {
    let operandRule = this.getOperandRule(rule)

    if (['metrics', 'common_metric'].includes(operandRule.element_type)) {
      this.queryElement = {
        elementID: operandRule.element_id,
        type: operandRule.element_type,
        property: operandRule.element_property
      }
    } else if (operandRule.element_type === 'performographics') {
      this.prepareMetricElement(rule, operandRule.element_type, 'value')
    } else {
      this.prepareGeneralPropertyElement(rule)
    }
  }

  prepareFinancialStatementElement(rule, elType, elProperty) {
    // let operandRule = null
    // if (this.isTreeSelectionRule(rule)) {
    //   operandRule = hasChildOperand
    //     ? this.getChildOperandRuleForTreeRule(rule)
    //     : this.getOperandRuleForTreeRule(rule)
    // } else {
    //   operandRule = hasChildOperand
    //     ? this.getChildOperandRule(rule)
    //     : this.getOperandRule(rule)
    // }
    this.queryElement = {
      elementID: rule.operand,
      type: elType,
      property: elProperty
    }
  }

  prepareGeneralPropertyElement(rule) {
    let operandRule = this.getOperandRule(rule)
    this.queryElement = {
      elementID: null,
      type: operandRule.element_type,
      property: operandRule.element_property
    }
  }

  buildSetDetails() {
    return this.query.quantiles.map((quantile, index) => {
      return {
        name: quantile.name,
        definition: {
          operator: quantile.operator,
          value:
            quantile.quantileType === 'select'
              ? quantile.values.map(val => val.id)
              : quantile.operator === 'less or equal'
              ? [quantile.less_or_equal_to]
              : quantile.operator === 'greater'
              ? [quantile.greater_than]
              : [quantile.greater_than, quantile.less_or_equal_to],
          count: quantile.count
        },
        output_sequence: index + 1
      }
    })
  }

  transformElement() {
    let element = {
      element_id: this.queryElement.elementID,
      element_type: this.queryElement.type,
      element_property: this.queryElement.property
    }

    if (this.queryElement.section) {
      element.element_section = this.queryElement.section
    }

    return {
      element: [element],
      criteria: { operator: this.query.operator, value: this.query.value }
    }
  }

  get transform() {
    return {
      element: this.transformElement(),
      setDetails: this.buildSetDetails()
    }
  }
}

export default SegmentRuleTransformer
