<template>
  <div>
    <b-card
      :class="[validSetDetails ? '' : 'quantile-error', 'std-border mt-3 mb-2']"
      header-class="p-2"
      body-class="p-2"
    >
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-11 col-md-11 text-left">
            <h6 class="mb-0 d-inline-block font-weight-bold">
              <label for="operator" class="d-inline-block mb-0">
                Segmentation Set Details
              </label>
            </h6>
          </div>
        </div>
      </div>
      <component
        :is="quantileComponent"
        :quantiles="segmentRule.quantiles"
        :element="element"
        :totalQuantile="totalQuantile"
        ref="quantileRule"
        v-if="showQuantiles"
      ></component>
    </b-card>
    <div v-if="!validSetDetails" class="error-msg">
      <small>
        segment set details invalid, please fill missing fields / correct
        invalid entries
      </small>
    </div>
  </div>
</template>

<script>
// global
import { mapState } from 'vuex'
// ui components
import QuantileByNumber from './QuantileByNumber'
import QuantileBySelect from './QuantileBySelect'
// helpers
import SegmentRuleTransformer from '../helpers/segment.rule.transformer'
// utils
import deepClone from '@/utilities.js'

export default {
  name: 'SegmentQuantile',
  components: {
    QuantileByNumber,
    QuantileBySelect
  },
  props: {
    segmentRule: {
      type: Object,
      required: true
    },
    quantileType: {
      type: String,
      required: true
    },
    quantileOptions: {
      type: Array,
      required: false
    },
    totalQuantile: {
      type: Number,
      required: true
    },
    isEditMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      element: null,
      showQuantiles: false
    }
  },
  created() {
    this.prepareComponent()
  },
  computed: {
    ...mapState('IndustryReporter', {
      validSetDetails: state => state.validSetDetails
    }),
    quantileComponent() {
      return this.quantileType === 'number'
        ? 'quantile-by-number'
        : 'quantile-by-select'
    }
  },
  methods: {
    prepareComponent() {
      this.showQuantiles = false
      this.$nextTick(() => {
        if (typeof this.segmentRule.quantiles[0] === 'undefined') {
          this.prepareQuantiles()
        }
        this.prepareElement()
        this.showQuantiles = true
      })
    },
    prepareQuantiles() {
      let formattedQuantiles = this.segmentRule.quantiles.map(
        (quantile, index) => {
          return {
            id: index + 1,
            quantileType: this.quantileType,
            name: this.getQuantileName(index),
            greater_than: index === 0 ? 'Minimum' : null,
            less_or_equal_to: index + 1 === this.totalQuantile ? 'Max' : null,
            operator: this.getQuantileOperator(index),
            options: this.formatQuantileOptions(),
            values: [],
            count: '-',
            loading: false
          }
        }
      )

      this.segmentRule.quantiles = formattedQuantiles
    },
    prepareElement() {
      this.element = new SegmentRuleTransformer(
        this.segmentRule
      ).transform.element
    },
    getQuantileName(index) {
      return 'Segment ' + (index + 1)
    },
    getQuantileOperator(index) {
      if (this.quantileType === 'number') {
        return index === 0
          ? 'less or equal'
          : index + 1 === this.totalQuantile
          ? 'greater'
          : 'between'
      } else {
        return this.segmentRule.operator
      }
    },
    formatQuantileOptions() {
      return deepClone(this.quantileOptions)
    },
    checkQuantileRuleValidity() {
      this.$refs.quantileRule.checkSetDetailsValidity()
    }
  },
  watch: {
    totalQuantile: {
      handler: function() {
        this.prepareComponent()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rule-box-cnt {
  background-color: #f5f5f5;
}

.close-icon {
  margin-left: auto;
}

.rule-block,
.rule-box {
  width: 100%;
}

.rule-operator {
  margin-right: 0.5rem;
  min-width: 2rem;
  font-weight: bold;
}

.rule-count {
  margin-left: 0.5rem;
  min-width: 3.2rem;
  font-weight: bold;
}

.rule-error {
  border: solid 1px #dc3545;
  border-left: solid 1px #dc3545 !important;
  background: #fae3e3;
}

.clone-rule-icon {
  font-size: 1rem;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.quantile-error {
  border: solid 1px #dc3545;
  border-left: solid 1px #dc3545 !important;
}
</style>
