<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="row px-1">
          <div class="col-sm-3 font-weight-bold text-muted">
            Name <span class="error-msg">*</span>
          </div>
          <div class="col-sm-3 font-weight-bold text-muted">
            Greater Than
          </div>
          <div class="col-sm-3 font-weight-bold text-muted">
            Less or Equal To
          </div>
          <div class="col-sm-3 font-weight-bold text-muted">
            Count
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div :class="['p-1', 'rounded']">
          <div
            class="row py-1"
            v-for="(quantile, index) in quantiles"
            :key="index"
          >
            <div class="col-sm-3">
              <input
                type="text"
                v-model="quantile.name"
                class="form-control rounded w-100"
              />
            </div>
            <div class="col-sm-3">
              <input
                type=""
                v-model="quantile.greater_than"
                class="form-control rounded w-100"
                :disabled="true"
              />
            </div>
            <div class="col-sm-3">
              <b-form-input
                :type="isMaxRangeField(quantile) ? 'text' : 'number'"
                v-model="quantile.less_or_equal_to"
                :class="[
                  $v.quantiles.$each[index].less_or_equal_to.withinRange
                    ? ''
                    : 'is-invalid',
                  'form-control rounded w-100'
                ]"
                :disabled="isMaxRangeField(quantile)"
                @blur="fetchRelatedQuantilesCount(quantile)"
              />
              <b-form-invalid-feedback
                v-if="!$v.quantiles.$each[index].less_or_equal_to.withinRange"
                >not a valid range</b-form-invalid-feedback
              >
            </div>
            <div class="col-sm-3">
              <span v-if="quantile.loading">
                <i class="fa fa-spinner fa-spin"></i>
              </span>
              <span v-else>
                {{ quantile.count }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script charset="utf-8">
// global
import { mapState, mapMutations } from 'vuex'
// api
import segmentationsAPI from '@/api/finapps/segmentations'
// validations
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'QuantileByNumber',
  props: {
    quantiles: {
      type: Array,
      required: true
    },
    element: {
      type: Object,
      required: true
    },
    totalQuantile: {
      type: Number,
      required: true
    },
    options: {
      type: Array,
      required: false
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('IndustryReporter', {
      selectedGroups: state => state.selectedGroups
    })
  },
  methods: {
    ...mapMutations('IndustryReporter', ['setSegmentSetDetailsValidity']),
    isMaxRangeField(quantile) {
      return quantile.less_or_equal_to === 'Max'
    },
    fetchRelatedQuantilesCount(quantile) {
      this.fetchPrevQuantileCount(quantile)
      this.fetchCurrentQuantileCount(quantile)
      this.fetchNextQuantileCount(quantile)
    },
    fetchPrevQuantileCount(quantile) {
      let prevQuantile = this.quantiles.find(q => q.id === quantile.id - 1)

      if (prevQuantile) {
        if (prevQuantile.count === '-') {
          this.fetchQuantileCount(prevQuantile)
        }
      }
    },
    fetchCurrentQuantileCount(quantile) {
      this.fetchQuantileCount(quantile)
    },
    fetchNextQuantileCount(quantile) {
      let nextQuantile = this.quantiles.find(q => q.id === quantile.id + 1)

      if (nextQuantile) {
        nextQuantile.greater_than = quantile.less_or_equal_to

        if (this.hasValidNextQuantile(quantile)) {
          this.fetchQuantileCount(nextQuantile)
        }
      }
    },
    fetchQuantileCount(quantile) {
      this.$v.$touch()

      let quantileIndex = this.quantiles.findIndex(q => q.id === quantile.id)

      if (!this.$v.quantiles.$each[quantileIndex].$invalid) {
        quantile.loading = true

        let setDetail = {
          operator: quantile.operator,
          value: [quantile.greater_than, quantile.less_or_equal_to].filter(
            qv => parseFloat(qv) || parseFloat(qv) === 0
          )
        }
        let groupIDs = this.selectedGroups.map(sg => ({
          id: sg.id,
          group_type: sg.group_type
        }))

        segmentationsAPI.setDetailCount(this.element, setDetail, groupIDs).then(
          res => {
            quantile.count = res.matching_count
            quantile.loading = false
          },
          () => {
            quantile.loading = false
            quantile.count = '-'
          }
        )
      } else {
        quantile.count = '-'
      }
    },
    hasValidNextQuantile(quantile) {
      let nextQuantile = this.quantiles.find(q => q.id === quantile.id + 1)
      return nextQuantile && nextQuantile.less_or_equal_to
    },
    checkSetDetailsValidity() {
      this.$v.$touch()
      this.setSegmentSetDetailsValidity(!this.$v.$invalid)
    }
  },
  validations: {
    quantiles: {
      $each: {
        name: {
          required
        },
        greater_than: {
          required
        },
        less_or_equal_to: {
          required,
          withinRange(value, quantile) {
            if (quantile.less_or_equal_to) {
              let isValidWithinQuantile, isValidBetweenQuantiles

              let nextQuantile = this.quantiles.find(
                q => q.id === quantile.id + 1
              )

              isValidWithinQuantile = [1, this.totalQuantile].includes(
                quantile.id
              )
                ? true
                : parseFloat(quantile.greater_than) <
                  parseFloat(quantile.less_or_equal_to)

              if (nextQuantile && nextQuantile.less_or_equal_to) {
                if (nextQuantile.id === this.totalQuantile) {
                  isValidBetweenQuantiles = true
                } else {
                  isValidBetweenQuantiles =
                    parseFloat(quantile.less_or_equal_to) <
                    parseFloat(nextQuantile.less_or_equal_to)
                }
              } else {
                isValidBetweenQuantiles = true
              }
              return isValidWithinQuantile && isValidBetweenQuantiles
            } else {
              return true
            }
          }
        }
      }
    }
  }
}
</script>
