<template>
  <b-modal
    size="xl"
    id="segmentModal"
    modal-class="std-ultra-wide-modal segment-modal"
    header-class="px-3 pt-3 pb-2"
    ref="segmentModal"
    @show="loadInitData()"
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
  >
    <template slot="modal-header">
      <h5 class="modal-title">
        {{
          isEditMode
            ? `Update Segmentation - ${segment.name}`
            : 'Create Segmentation'
        }}
      </h5>
      <button type="button" class="close" @click.prevent="closeModal">x</button>
    </template>

    <div :class="{ processing: fetchingFIs }">
      <v-wait for="loadingSegmentData">
        <template slot="waiting">
          <content-placeholders :rounded="true" class="pt-4">
            <content-placeholders-text :lines="15"></content-placeholders-text>
          </content-placeholders>
        </template>
        <div>
          <h6 class="mb-2">Select data element for segmentation.</h6>
          <segment
            :rules="segmentRules"
            :query="segmentRule"
            :isEditMode="isEditMode"
            ref="segment"
          ></segment>
          <div class="mt-3">
            <div class="row">
              <div class="offset-sm-1 col-sm-5">
                <b-form-group>
                  <label for="Search" class="mb-1">
                    <b>Segment Description</b><i class="pl-1">(Optional)</i>
                  </label>
                  <b-form-textarea
                    id="description"
                    class="form-control rounded"
                    v-model="segmentDescription"
                    rows="4"
                    max-rows="4"
                  >
                  </b-form-textarea>
                </b-form-group>
              </div>
              <div class="col-sm-5">
                <b-form-group>
                  <label for="Search" class="mb-1">
                    <b>Segment Name</b>
                  </label>
                  <b-form-input
                    type="text"
                    class="form-control rounded"
                    placeholder="Enter a Segment Name (required)"
                    v-model="segmentName"
                    :class="[
                      $v.segmentName.$error ? 'is-invalid' : '',
                      'mb-2 mr-sm-2 mb-sm-0'
                    ]"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!$v.segmentName.required"
                    >Segment name required</b-form-invalid-feedback
                  >
                </b-form-group>
                <div class="row">
                  <div class="col-sm-12 text-center pt-1">
                    <b-button
                      size
                      variant="secondary"
                      class="fw-btn mx-3"
                      @click.prevent="closeModal"
                      >Close</b-button
                    >
                    <b-button
                      size=""
                      variant="primary"
                      class="fw-btn-ext mx-3"
                      @click="saveSegment"
                      >Save Segment</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-wait>
    </div>
  </b-modal>
</template>

<script>
// global
import { mapState, mapActions, mapMutations } from 'vuex'
// ui components
import Segment from './Segment'
//validations
import { required } from 'vuelidate/lib/validators'
// utils
import _ from 'lodash'
import deepClone from '@/utilities.js'
// api
import segmentationsAPI from '@/api/finapps/segmentations'
// helpers
import SegmentRuleTransformer from '../helpers/segment.rule.transformer'
import SegmentRuleRetransformer from '../helpers/segment.rule.retransformer'

export default {
  name: 'IRFSegmentModal',
  components: {
    Segment
  },
  props: {
    segment: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      segmentName: null,
      segmentDescription: null,
      baseRule: {
        id: null,
        operator: null,
        ruleId: null,
        source: null,
        operand: null,
        childOperand: null,
        value: null,
        error: false,
        loading: false,
        segmentType: 'manual',
        quantiles: [],
        fiType: 'both'
      },
      segmentRule: {},
      loadSegment: null,
      queryStateOnLoad: {}
    }
  },
  computed: {
    ...mapState('AdvancedQuery', {
      queryRules: state => state.queryRules,
      fetchingFIs: state => state.fetchingFIs,
      isValidQuery: state => state.isValidQuery
    }),
    ...mapState('IndustryReporter', {
      selectedSegment: state => state.selectedSegment,
      segmentRules: state => state.segmentRules,
      validSet: state => state.validSet,
      validSetDetails: state => state.validSetDetails,
      validSegment: state => state.validSegment,
      groupFIType: state => state.groupFIType
    }),
    editMode() {
      return this.segment && this.segment.id
    },
    isValidSegment() {
      return this.validSegment && this.validSet && this.validSetDetails
    },
    isEditMode() {
      return this.segment.id ? true : false
    },
    isSegmentNameUnchanged() {
      return (
        this.selectedSegment.name.toLowerCase().trim() ===
        this.segmentName.toLowerCase().trim()
      )
    }
  },
  methods: {
    ...mapActions('AdvancedQuery', ['fetchQueryRules', 'fetchFICount']),
    ...mapActions('IndustryReporter', ['fetchSegmentRules']),
    ...mapMutations('AdvancedQuery', [
      'setFICount',
      'resetQueryData',
      'setQueryValidity',
      'setSelectedQuery',
      'setProcessingStatus',
      'resetFICountAndPriceData'
    ]),
    ...mapMutations('IndustryReporter', [
      'setSegmentValidity',
      'setSegmentSetValidity',
      'setSegmentSetDetailsValidity'
    ]),
    loadInitData() {
      this.resetValidations()
      if (this.isEditMode) {
        this.segmentName = this.selectedSegment.name
        this.segmentDescription = this.selectedSegment.description
      } else {
        this.segmentName = null
        this.segmentDescription = null
      }

      this.$wait.start('loadingSegmentData')
      if (this.editMode) {
        Promise.all([
          this.fetchSegmentRules(),
          this.fetchSegmentDetails()
        ]).then(() => {
          this.prepareRuleAndQuantiles()
          this.segmentRule.fiType = this.groupFIType.value
          this.$wait.end('loadingSegmentData')
        })
      } else {
        this.fetchSegmentRules().then(() => {
          this.segmentRule = deepClone(this.baseRule)
          this.segmentRule.fiType = this.groupFIType.value
          this.queryStateOnLoad = deepClone(this.segmentRule)
          this.$wait.end('loadingSegmentData')
        })
      }
    },
    prepareRuleAndQuantiles() {
      let rule = new SegmentRuleRetransformer(this.loadSegment).retransform
      rule.quantiles.map(quantile => {
        return {
          id: quantile.id,
          name: quantile.name,
          quantileType: quantile.quantileType,
          greater_than: quantile.greater_than,
          less_or_equal_to: quantile.less_or_equal_to,
          operator: quantile.operator,
          options: quantile.options,
          values: quantile.values,
          count: quantile.count,
          loading: false
        }
      })
      this.segmentRule = deepClone(rule)
      this.queryStateOnLoad = deepClone(rule)
    },
    fetchSegmentDetails() {
      return segmentationsAPI.editSegment(this.segment.id).then(res => {
        this.loadSegment = res
      })
    },
    ruleAltered() {
      if (this.isEditMode) {
        return false
      }
      return !_.isEqual(this.queryStateOnLoad, this.segmentRule)
    },
    closeModal() {
      if (this.ruleAltered()) {
        return this.$dialog
          .confirm(
            {
              title: 'Alert',
              body: 'Your changes are not saved, do you still want to close?'
            },
            {
              okText: 'Yes',
              cancelText: 'No'
            }
          )
          .then(() => {
            this.$bvModal.hide('segmentModal')
          })
          .catch(() => {})
      } else {
        this.$bvModal.hide('segmentModal')
      }
    },
    saveSegment() {
      this.isEditMode && this.isSegmentNameUnchanged
        ? this.updateSegment()
        : this.createSegment()
    },
    createSegment() {
      this.checkValidations()

      if (this.isValidSegment) {
        let rule = new SegmentRuleTransformer(this.segmentRule).transform

        segmentationsAPI
          .saveSegment(
            this.segmentName,
            this.segmentDescription,
            rule.element,
            rule.setDetails
          )
          .then(res => {
            if (res && res.set_details) {
              this.$toasted.show('Segment successfully created.', {
                icon: 'user-circle',
                type: 'success'
              })
              this.$emit('segmentCreated')
              this.$bvModal.hide('segmentModal')
            }
          })
      }
    },
    updateSegment() {
      this.checkValidations()

      if (this.isValidSegment) {
        let rule = new SegmentRuleTransformer(this.segmentRule).transform

        segmentationsAPI
          .updateSegment(
            this.segmentRule.id,
            this.segmentName,
            this.segmentDescription,
            rule.element,
            rule.setDetails
          )
          .then(res => {
            if (res && res.set_details) {
              this.$toasted.show('Segment successfully updated.', {
                icon: 'user-circle',
                type: 'success'
              })
              this.$emit('segmentUpdated')
              this.$bvModal.hide('segmentModal')
            }
          })
      }
    },
    checkValidations() {
      this.$v.$touch()
      this.setSegmentValidity(!this.$v.$invalid)
      this.$refs.segment.checkSetValidity()
    },
    resetValidations() {
      this.$v.$reset()
      this.setSegmentValidity(true)
      this.setSegmentSetValidity(true)
      this.setSegmentSetDetailsValidity(true)
    }
  },
  validations: {
    segmentName: {
      required
    }
  }
}
</script>

<style lang="scss" scoped>
.fi-count-component {
  background-color: #f5f5f5;
}
</style>
