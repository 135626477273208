<template>
  <div>
    <div class="row no-gutters">
      <div class="col-sm-8 pt-3 pl-3 pb-5">
        <div class="row">
          <div class="col-sm-12">
            <h6 class="mb-2">
              Select a Segmentation for the report (optional).
              <span class="text-muted">
                (New Segmentation sets can also be created using the button
                below.)
              </span>
            </h6>
          </div>
        </div>

        <b-card-group deck>
          <b-card
            class="std-border std-list-card"
            body-class="p-0"
            header-class="pl-3"
          >
            <div slot="header">
              <h6 class="mb-0">
                <b>My Saved Segmentation</b>
              </h6>
            </div>
            <v-wait for="loadingSavedSegments">
              <template slot="waiting">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="15"
                  ></content-placeholders-text>
                </content-placeholders>
              </template>
              <b-list-group class="selectable-list-group">
                <div v-if="filteredMySegments.length">
                  <b-list-group-item
                    v-for="(mySegment, index) in filteredMySegments"
                    :key="index"
                    :class="[
                      isSelectedSegment(mySegment) ? 'selected p-2' : 'p-2'
                    ]"
                    @click="selectSegment(mySegment)"
                  >
                    <span :id="`ss-${mySegment.id}`">
                      {{ mySegment.name }}
                    </span>
                    <span class="manage--query-group">
                      <button
                        type="submit"
                        class="float-right btn btn-secondary btn-sm mx-1"
                        @click.stop="deleteSegment(mySegment)"
                      >
                        Delete
                      </button>
                      <button
                        type="submit"
                        class="float-right btn btn-primary btn-sm mx-1"
                        @click.stop="editSegment(mySegment)"
                      >
                        Edit
                      </button>
                    </span>
                    <b-popover
                      :target="`ss-${mySegment.id}`"
                      triggers="hover"
                      placement="left"
                      v-if="mySegment.description"
                    >
                      {{ mySegment.description }}
                    </b-popover>
                  </b-list-group-item>
                </div>
                <div v-else>
                  <b-list-group-item class="text-center">
                    <i>No Segments found</i>
                  </b-list-group-item>
                </div>
              </b-list-group>
            </v-wait>
          </b-card>
          <b-card
            class="std-border std-list-card"
            body-class="p-0"
            header-class="pl-3"
          >
            <div slot="header">
              <h6 class="mb-0"><b>FI Navigator Segmentation</b></h6>
            </div>
            <v-wait for="loadingNavigatorSegments">
              <template slot="waiting">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="15"
                  ></content-placeholders-text>
                </content-placeholders>
              </template>
              <b-list-group class="selectable-list-group">
                <div v-if="filteredNavigatorSegments.length">
                  <b-list-group-item
                    v-for="(navSegment, index) in filteredNavigatorSegments"
                    :key="index"
                    :class="[
                      isSelectedSegment(navSegment) ? 'selected p-2' : 'p-2'
                    ]"
                    @click="selectSegment(navSegment)"
                  >
                    <span :id="`ns-${navSegment.id}`">
                      {{ navSegment.name }}
                    </span>

                    <b-popover
                      :target="`ns-${navSegment.id}`"
                      triggers="hover"
                      placement="left"
                      v-if="navSegment.description"
                    >
                      {{ navSegment.description }}
                    </b-popover>
                  </b-list-group-item>
                </div>
                <div v-else>
                  <b-list-group-item class="text-center">
                    <i>No Segments found</i>
                  </b-list-group-item>
                </div>
              </b-list-group>
            </v-wait>
          </b-card>
        </b-card-group>

        <div class="row text-center">
          <div class="col-sm-12">
            <div class="row text-center pt-2">
              <div class="col-sm-12">
                <b-button
                  size=""
                  variant="secondary"
                  class="fw-btn mt-3 mx-3 px-2"
                  @click="resetSelections"
                  >Reset</b-button
                >
                <b-button
                  size=""
                  variant="primary"
                  class="fw-btn mt-3 mx-3 px-2"
                  @click="createSegment"
                  >Create Segment</b-button
                >
                <router-link
                  tag="button"
                  :to="{ path: 'select_report' }"
                  class="btn btn-primary fw-btn mt-3 mx-3"
                >
                  Continue
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="selectedSegment.id">
          <div class="col-sm-12">
            <b-card
              class="std-border metrics-card mt-4"
              body-class="p-1"
              header-class="pl-3"
            >
              <div slot="header">
                <h6 class="mb-0">
                  <b>{{ selectedSegment.name }} - Details</b>
                </h6>
              </div>
              <v-wait for="setDetailsLoading">
                <template slot="waiting">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="15"
                    ></content-placeholders-text>
                  </content-placeholders>
                </template>
                <div v-if="setDetails.length">
                  <b-list-group class="selectable-list-group">
                    <b-list-group-item
                      v-for="(setDetail, index) in setDetails"
                      :key="index"
                      class="p-2"
                    >
                      {{ setDetail.name }}
                    </b-list-group-item>
                  </b-list-group>
                </div>
                <div v-else>
                  <b-list-group class="selectable-list-group">
                    <b-list-group-item class="p-3 text-center">
                      No details found.
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </v-wait>
            </b-card>
          </div>
        </div>
      </div>

      <div class="offset-sm-1 col-sm-3 px-0 std-border">
        <b-card class="" header-class="p-2" body-class="px-3 pt-3">
          <div slot="header">
            <div class="d-flex align-items-center">
              <div>
                <i class="cuis-filter card-icon-font"></i>
              </div>
              <div class="px-2">
                <h6 class="mb-0">
                  <b>Segment Filters</b>
                </h6>
                <div class="text-muted">
                  Select options to filter segments.
                </div>
              </div>
            </div>
          </div>
          <div class="font-weight-bold mb-1">
            Segment Categories:
          </div>
          <div class="report-output-view">
            <multiselect
              track-by="value"
              label="text"
              v-model="filters"
              :allowEmpty="true"
              :showLabels="false"
              :options="filterOptions"
              class="rw-multi-select aq-multiselect"
              placeholder="Select Categories"
              :multiple="true"
              :close-on-select="false"
              group-label="label"
              group-values="options"
              :group-select="false"
            ></multiselect>
          </div>
        </b-card>
      </div>

      <segment-modal
        @segmentCreated="segmentCreated"
        @segmentUpdated="segmentUpdated"
        :segment="segmentForEdit"
      ></segment-modal>
    </div>
  </div>
</template>

<script>
// global
import { mapState, mapMutations } from 'vuex'
//api
import segmentationsAPI from '@/api/finapps/segmentations'
//ui components
import SegmentModal from '../components/SegmentModal'
import Multiselect from 'vue-multiselect'

export default {
  name: 'IRSegmentation',
  components: {
    SegmentModal,
    Multiselect
  },
  data() {
    return {
      mySegments: [],
      navigatorSegments: [],
      setDetails: [],
      filters: [],
      filterOptions: [
        {
          label: 'Please select',
          options: [
            { value: 'firmographics', text: 'Firmographics' },
            { value: 'market', text: 'Market Area' },
            { value: 'segmentation', text: 'Segmentation' },
            { value: 'technographics', text: 'Technographics' },
            // { value: 'job_search', text: 'Job Search' },
            // { value: 'site_search', text: 'Site Search' },

            { value: 'other', text: 'Other/Unassigned' }
          ]
        },
        {
          label: 'Financial Data',
          options: [
            { value: 'performographics', text: 'Performographics' },
            {
              value: 'common-metrics',
              text: 'Common Metrics (Bank/CU)'
            },
            {
              value: 'detailed-metrics',
              text: 'Detailed Metrics'
            },
            {
              value: 'financial-statements',
              text: 'Common Statements (Bank/CU)'
            },
            {
              value: 'detailed-statements',
              text: 'Detailed Statements'
            }
            // { value: 'profit', text: 'Profit' },
            // { value: 'growth', text: 'Growth' },
            // { value: 'risk', text: 'Risk' }
          ]
        }

        // {
        //   label: 'Financial Statements',
        //   options: [
        //     { value: 'balance_sheet', text: 'Balance Sheet' },
        //     { value: 'income_statement', text: 'Income Statement' },
        //     { value: 'deposit_details', text: 'Deposit Details' },
        //     { value: 'credit_quality', text: 'Credit Quality' }
        //   ]
        // },
        // {
        //   label: 'Technographics',
        //   options: [
        //     {
        //       value: 'retail_services',
        //       text: 'Retail Services'
        //     },
        //     {
        //       value: 'business_services',
        //       text: 'Business Services'
        //     },
        //     {
        //       value: 'operating_solutions',
        //       text: 'Operating Solutions'
        //     }
        //   ]
        // }
      ],
      segmentForEdit: {}
    }
  },
  created() {
    if (this.selectedGroups.length) {
      this.loadInitData()
    } else {
      this.$toasted.global.invalid('Please select FI Group.')
      this.$router.push('select_fi_group')
    }
  },
  computed: {
    ...mapState('IndustryReporter', {
      groupFIType: state => state.groupFIType,
      selectedGroups: state => state.selectedGroups,
      selectedReport: state => state.selectedReport,
      selectedSegment: state => state.selectedSegment
    }),
    appliedTags() {
      return this.filters.map(filter => filter.value)
    },
    filteredMySegments() {
      return this.mySegments
        .filter(ms => {
          if (this.appliedTags.length) {
            return (
              ['both', this.groupFIType.value].includes(ms.fi_type) &&
              !ms.is_public &&
              ms.taggings.some(tag => this.appliedTags.includes(tag))
            )
          } else {
            return (
              ['both', this.groupFIType.value].includes(ms.fi_type) &&
              !ms.is_public
            )
          }
        })
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    },
    filteredNavigatorSegments() {
      return this.navigatorSegments.filter(ns => {
        if (this.appliedTags.length) {
          return (
            ['both', this.groupFIType.value].includes(ns.fi_type) &&
            ns.taggings.some(tag => this.appliedTags.includes(tag))
          )
        } else {
          return ['both', this.groupFIType.value].includes(ns.fi_type)
        }
      })
    },
    hasSelectedSegemnt() {
      return this.selectedSegment.id
    }
  },
  methods: {
    ...mapMutations('IndustryReporter', [
      'setSelectedSegment',
      'setShowSegments'
    ]),
    loadInitData() {
      Promise.all([
        this.loadSavedSegments(),
        this.loadNavigatorSegments()
      ]).then(() => {
        this.fetchSegmentDetails()
      })
    },
    loadSavedSegments() {
      this.$wait.start('loadingSavedSegments')
      return segmentationsAPI
        .savedSegments()
        .then(res => {
          this.mySegments = res.segments
        })
        .finally(() => {
          this.$wait.end('loadingSavedSegments')
        })
    },
    loadNavigatorSegments() {
      this.$wait.start('loadingNavigatorSegments')
      return segmentationsAPI
        .navigatorSegments()
        .then(res => {
          this.navigatorSegments = res.segments
        })
        .finally(() => {
          this.$wait.end('loadingNavigatorSegments')
        })
    },
    selectSegment(set) {
      if (this.selectedSegment.id === set.id) {
        this.resetSelectedSegment()
      } else {
        this.setSelectedSegment(set)
        this.setShowSegments(true)
        this.fetchSegmentDetails()
      }
    },
    fetchSegmentDetails() {
      if (this.selectedSegment.id) {
        this.$wait.start('setDetailsLoading')
        return segmentationsAPI
          .segmentDetails(this.selectedSegment.id)
          .then(res => {
            this.setDetails = res.set_details
            this.$wait.end('setDetailsLoading')
          })
      }
    },
    resetSelections() {
      this.resetSelectedSegment()
      this.segmentForEdit = {}
      this.filters = []
    },
    createSegment() {
      this.resetSelectedSegment()
      this.segmentForEdit = {}
      this.$nextTick(function() {
        this.$bvModal.show('segmentModal')
      })
    },
    segmentCreated() {
      this.loadSavedSegments()
    },
    segmentUpdated() {
      this.loadSavedSegments()
    },
    editSegment(segment) {
      this.setSelectedSegment(segment)
      this.setShowSegments(true)
      this.fetchSegmentDetails()
      this.segmentForEdit = segment
      this.$nextTick(function() {
        this.$bvModal.show('segmentModal')
      })
    },
    deleteSegment(segment) {
      return this.$dialog
        .confirm(
          {
            title: 'Alert',
            body: `Are you sure, you want to delete - ${segment.name} ?`
          },
          {
            okText: 'Delete',
            cancelText: 'Cancel'
          }
        )
        .then(() => {
          segmentationsAPI.deleteSegment(segment.id).then(res => {
            if (res.success) {
              this.removeSegmentFromList(segment)
              if (this.isSelectedSegment(segment)) {
                this.resetSelectedSegment()
              }
              this.$toasted.global.action_success(
                'Segment successfully deleted.'
              )
            }
          })
        })
        .catch(() => {})
    },
    isSelectedSegment(segment) {
      return this.selectedSegment.id === segment.id
    },
    removeSegmentFromList(segment) {
      let segmentIndex = this.mySegments.findIndex(ms => ms.id === segment.id)
      this.mySegments.splice(segmentIndex, 1)
    },
    resetSelectedSegment() {
      this.setSelectedSegment({ id: null, name: null })
      this.setShowSegments(false)
    }
  },
  watch: {
    filters: {
      handler: function() {
        if (this.hasSelectedSegemnt && this.appliedTags.length) {
          let hasMatchingTag = this.selectedSegment.taggings.some(tag =>
            this.appliedTags.includes(tag)
          )
          if (!hasMatchingTag) {
            this.resetSelectedSegment()
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.manage--query-group {
  display: none;

  .btn {
    border-radius: 0.25rem;
    padding-top: 2px;
    padding-bottom: 2px;
    width: 4rem;
  }
}

.selectable-list-group {
  .list-group-item {
    &:hover {
      .manage--query-group {
        display: inline;
      }
    }
  }
}
</style>
