<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="row px-1">
          <div class="col-sm-3 font-weight-bold text-muted">
            Name <span class="error-msg">*</span>
          </div>
          <div class="col-sm-3 font-weight-bold text-muted">
            Includes
          </div>
          <div class="col-sm-3 font-weight-bold text-muted">
            Count
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div :class="['p-1', 'rounded']">
          <div
            class="row py-1"
            v-for="(quantile, index) in quantiles"
            :key="index"
          >
            <div class="col-sm-3">
              <input
                type="text"
                v-model="quantile.name"
                class="form-control rounded w-100"
              />
            </div>

            <div class="col-sm-3">
              <multiselect
                v-model="quantile.values"
                track-by="id"
                label="label"
                :options="quantileOptions(quantile)"
                :multiple="true"
                :close-on-select="false"
                :showLabels="false"
                :placeholder="quantile.values.length ? '' : 'Please select'"
                class="simple-select group-select form-control rounded advanced-query-select w-100"
                @close="fetchCount(quantile)"
              >
                <template
                  slot="selection"
                  slot-scope="{ values }"
                  v-if="values.length"
                >
                  {{ values.length }}
                  {{ values.length | pluralize('option') }} selected
                </template>
              </multiselect>
            </div>
            <div class="col-sm-3">
              <span v-if="quantile.loading">
                <i class="fa fa-spinner fa-spin"></i>
              </span>
              <span v-else>
                {{ quantile.count }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3">
        <div class="p-1">
          <b>All Others</b>
        </div>
      </div>
      <div class="col-sm-3"></div>
      <div class="col-sm-3">
        {{ allOthersCount }}
      </div>
    </div>
  </div>
</template>

<script charset="utf-8">
// global
import { mapState, mapMutations } from 'vuex'
// api
import segmentationsAPI from '@/api/finapps/segmentations'
// ui components
import Multiselect from 'vue-multiselect'
// validations
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'QuantileBySelect',
  components: {
    Multiselect
  },
  props: {
    quantiles: {
      type: Array,
      required: true
    },
    element: {
      type: Object,
      required: true
    },
    totalQuantile: {
      type: Number,
      required: true
    }
  },
  created() {
    this.quantiles.forEach(quantile => {
      this.updateRelatedQuantileOptions(quantile)
    })
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('IndustryReporter', {
      totalFICount: state => state.totalFICount,
      selectedGroups: state => state.selectedGroups
    }),
    allOthersCount() {
      let countByQuantile = this.quantiles
        .map(q => parseInt(q.count) || 0)
        .reduce((a, v) => a + v)
      let count = this.totalFICount - countByQuantile
      return count > 0 ? count : 0
    },
    allSelectedOptions() {
      return this.quantiles.map(q => q.values).flat()
    }
  },
  methods: {
    ...mapMutations('IndustryReporter', ['setSegmentSetDetailsValidity']),
    isMaxRangeFiled(quantile) {
      return quantile.less_or_equal_to === 'Max'
    },
    quantileOptions(quantile) {
      return quantile.options.filter(o => !o.skip)
    },
    fetchCount(quantile) {
      this.updateRelatedQuantileOptions(quantile)

      if (quantile.values.length) {
        quantile.loading = true

        let setDetail = {
          operator: quantile.operator,
          value: quantile.values.map(v => v.id)
        }

        let groupIDs = this.selectedGroups.map(sg => ({
          id: sg.id,
          group_type: sg.group_type
        }))

        segmentationsAPI.setDetailCount(this.element, setDetail, groupIDs).then(
          res => {
            quantile.count = res.matching_count
            quantile.loading = false
          },
          () => {
            quantile.loading = false
            quantile.count = '-'
          }
        )
      } else {
        quantile.count = '-'
      }
    },
    updateRelatedQuantileOptions(quantile) {
      this.quantiles.forEach(q => {
        if (q.id !== quantile.id) {
          q.options.forEach(option => {
            let optionSelected = this.allSelectedOptions.find(
              so => so.id === option.id
            )
              ? true
              : false
            let optionSelfSelected = q.values.find(v => v.id === option.id)
              ? true
              : false

            option.skip = optionSelected
              ? optionSelfSelected
                ? false
                : true
              : false
          })
        }
      })
    },
    checkSetDetailsValidity() {
      this.$v.$touch()
      this.setSegmentSetDetailsValidity(!this.$v.$invalid)
    }
  },
  validations: {
    quantiles: {
      $each: {
        name: {
          required
        },
        values: {
          required
        }
      }
    }
  }
}
</script>

<style lang="scss">
/* need to check if this validation required */

.advanced-query-select {
  min-height: 33px;

  .multiselect__select {
    height: 33px;
  }

  .multiselect__tags {
    min-height: 33px;
    padding-top: 0.25rem;

    .multiselect__placeholder {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
