import store from '@/store/store.js'

class SegmentRuleRetransformer {
  constructor(segment) {
    this.segment = segment
    this.queryRules = store.getters['IndustryReporter/segmentRules']
  }
  getRuleId(segmentRule) {
    if (
      [
        'Balance Sheet',
        'Income Statement',
        'Deposit Details',
        'Credit Quality'
      ].includes(segmentRule.label)
    ) {
      return 'financial-statements'
    } else if (
      [
        'Bank - Balance Sheet',
        'Bank - Income Statement',
        'Bank - Deposit Details',
        'Bank - Credit Quality',
        'CU - Balance Sheet',
        'CU - Income Statement',
        'CU - Deposit Details',
        'CU - Credit Quality'
      ].includes(segmentRule.label)
    ) {
      return 'detailed-statements'
    } else if (['Profit', 'Growth', 'Risk'].includes(segmentRule.label)) {
      return 'common-metrics'
    } else if (
      [
        'Bank - Profit',
        'Bank - Growth',
        'Bank - Risk',
        'CU - Profit',
        'CU - Growth',
        'CU - Risk'
      ].includes(segmentRule.label)
    ) {
      return 'detailed-metrics'
    } else {
      return segmentRule.id
    }
  }
  getOperandId(segmentLabel, operandRule) {
    if (
      [
        'Balance Sheet',
        'Income Statement',
        'Deposit Details',
        'Credit Quality',
        'Bank - Balance Sheet',
        'Bank - Income Statement',
        'Bank - Deposit Details',
        'Bank - Credit Quality',
        'CU - Balance Sheet',
        'CU - Income Statement',
        'CU - Deposit Details',
        'CU - Credit Quality',
        'Profit',
        'Growth',
        'Risk',
        'Bank - Profit',
        'Bank - Growth',
        'Bank - Risk',
        'CU - Profit',
        'CU - Growth',
        'CU - Risk'
      ].includes(segmentLabel)
    )
      return operandRule.element_id
    else return operandRule.id
  }
  getElementInfo() {
    let element = this.segment.set.element
    let queryElement = element.element[0]

    let elementInfo = {}
    let segmentRule = {}
    let operandRule = {}
    let childOperandRule = {}
    //let metricRules = []
    let technographicRules = []
    //let statementRules = []

    let elementType = queryElement.element_type

    /* Special Case: Total CU Members (10081), Deposit Accounts (1931), Share Accounts (10014) are metrics  come under Firmographics */
    if (['10081', '291', '1931', '10014'].includes(queryElement.element_id))
      elementType = 'firmographics'
    switch (elementType) {
      case 'market_area':
        segmentRule = this.queryRules.find(qr => qr.label === 'Market')
        operandRule = segmentRule.children.find(
          or => or.element_property === queryElement.element_property
        )

        elementInfo = {
          ruleId: segmentRule.id,
          operand: operandRule.id,
          childOperand: null,
          source: operandRule.source,
          cuSource: operandRule.cu_source,
          ruleType: operandRule.type,
          options: this.getRuleOptions(operandRule)
        }
        break
      case 'digital_channels':
        segmentRule = this.queryRules.find(
          qr => qr.label === 'Digital Channels'
        )
        operandRule = segmentRule.children.find(
          or => or.element_property === queryElement.element_property
        )

        elementInfo = {
          ruleId: segmentRule.id,
          operand: operandRule.id,
          childOperand: null,
          source: operandRule.source,
          cuSource: operandRule.cu_source,
          ruleType: operandRule.type,
          options: this.getRuleOptions(operandRule)
        }
        break
      case 'segmentation':
        segmentRule = this.queryRules.find(qr => qr.label === 'Segmentation')
        operandRule = segmentRule.children.find(
          or => or.element_property === queryElement.element_property
        )

        elementInfo = {
          ruleId: segmentRule.id,
          operand: operandRule.id,
          childOperand: null,
          source: operandRule.source,
          cuSource: operandRule.cu_source,
          ruleType: operandRule.type,
          options: this.getRuleOptions(operandRule)
        }
        break
      case 'firmographics':
        segmentRule = this.queryRules.find(qr => qr.label === 'Firmographics')
        if (['10081', '1931', '10014'].includes(queryElement.element_id))
          operandRule = segmentRule.children.find(
            sr => sr.element_id === queryElement.element_id
          )
        else
          operandRule = segmentRule.children.find(
            sr => sr.element_property === queryElement.element_property
          )

        elementInfo = {
          ruleId: segmentRule.id,
          operand: operandRule.id,
          childOperand: null,
          source: operandRule.source,
          cuSource: operandRule.cu_source,
          ruleType: operandRule.type,
          options: this.getRuleOptions(operandRule)
        }
        break
      case 'performographics':
        segmentRule = this.queryRules.find(
          qr => qr.label === 'Performographics'
        )

        operandRule = segmentRule.children.find(
          or =>
            or.element_type === queryElement.element_type &&
            or.id == queryElement.element_id
        )
        elementInfo = {
          ruleId: segmentRule.id,
          operand: operandRule.id,
          childOperand: null,
          source: operandRule.source,
          cuSource: operandRule.cu_source,
          ruleType: operandRule.type,
          options: this.getRuleOptions(operandRule)
        }
        break
      case 'metrics':
        segmentRule = this.queryRules.find(qr => qr.id === 'detailed-metrics')
        elementInfo = {
          ruleId: segmentRule.id,
          operand: queryElement.element_id,
          operandLabel: queryElement.metric_name,
          fiType: element.fi_type,
          childOperand: null,
          source: null,
          cuSource: null,
          parent: segmentRule.label,
          ruleType: 'number',
          options: null
        }
        break
      case 'common_metric':
        segmentRule = this.queryRules.find(qr => qr.id === 'common-metrics')
        elementInfo = {
          ruleId: segmentRule.id,
          operand: queryElement.element_id,
          operandLabel: queryElement.metric_name,
          parent: segmentRule.label,
          fiType: 'both',
          childOperand: null,
          source: null,
          cuSource: null,
          ruleType: 'number',
          options: null
        }

        // elementInfo = {
        //   ruleId: this.getRuleId(segmentRule),
        //   parent: segmentRule.label,
        //   operand: this.getOperandId(segmentRule.label, operandRule),
        //   operandLabel: operandRule.label,
        //   childOperand: null,
        //   source: operandRule.source,
        //   cuSource: operandRule.cu_source,
        //   ruleType: operandRule.type,
        //   options: this.getRuleOptions(operandRule)
        // }
        break
      case 'financial_statements':
        segmentRule = this.queryRules.find(
          qr => qr.id === 'detailed-statements'
        )

        elementInfo = {
          ruleId: segmentRule.id,
          operand: queryElement.element_id,
          operandLabel: queryElement.statement_name,
          fiType: element.fi_type,
          childOperand: null,
          source: null,
          cuSource: null,
          parent: segmentRule.label,
          ruleType: 'number',
          options: null
        }
        break
      case 'common_financial_statements':
        segmentRule = this.queryRules.find(
          qr => qr.id === 'financial-statements'
        )

        elementInfo = {
          ruleId: segmentRule.id,
          operand: queryElement.element_id,
          operandLabel: queryElement.statement_name,
          fiType: 'both',
          childOperand: null,
          source: null,
          cuSource: null,
          parent: segmentRule.label,
          ruleType: 'number',
          options: null
        }
        // statementRules = this.queryRules.filter(qr =>
        //   [
        //     'Balance Sheet',
        //     'Income Statement',
        //     'Deposit Details',
        //     'Credit Quality',
        //     'Bank - Balance Sheet',
        //     'Bank - Income Statement',
        //     'Bank - Deposit Details',
        //     'Bank - Credit Quality',
        //     'CU - Balance Sheet',
        //     'CU - Income Statement',
        //     'CU - Deposit Details',
        //     'CU - Credit Quality'
        //   ].includes(qr.label)
        // )

        // statementRules.some(sr => {
        //   return sr.children.some(or => {
        //     childOperandRule = or.children.find(
        //       cor => cor.element_id === queryElement.element_id
        //     )
        //     if (childOperandRule) {
        //       operandRule = or
        //       segmentRule = sr
        //     }
        //     return childOperandRule
        //   })
        // })

        // if (childOperandRule) {
        //   elementInfo = {
        //     ruleId: this.getRuleId(segmentRule),
        //     parent: segmentRule.label,
        //     service: operandRule.label,
        //     operand: this.getOperandId(segmentRule.label, childOperandRule),
        //     operandLabel: childOperandRule.label,
        //     childOperand: this.getOperandId(
        //       segmentRule.label,
        //       childOperandRule
        //     ),
        //     source: childOperandRule.source,
        //     cuSource: childOperandRule.cu_source,
        //     ruleType: childOperandRule.type,
        //     options: this.getRuleOptions(childOperandRule)
        //   }
        // } else {
        //   this.queryRules
        //     .filter(qr =>
        //       [
        //         'Income Statement',
        //         'Bank - Income Statement',
        //         'CU - Income Statement'
        //       ].includes(qr.label)
        //     )
        //     .some(isr => {
        //       operandRule = isr.children.find(
        //         or => or.element_id === queryElement.element_id
        //       )
        //       if (operandRule) {
        //         segmentRule = isr
        //       }

        //       return operandRule
        //     })

        //   elementInfo = {
        //     ruleId: this.getRuleId(segmentRule),
        //     parent: segmentRule.label,
        //     service: operandRule.label,
        //     operand: this.getOperandId(segmentRule.label, operandRule),
        //     operandLabel: operandRule.label,
        //     childOperand: this.getOperandId(segmentRule.label, operandRule),
        //     source: operandRule.source,
        //     cuSource: operandRule.cu_source,
        //     ruleType: operandRule.type,
        //     options: this.getRuleOptions(operandRule)
        //   }
        // }
        break
      case 'technographics':
        if (queryElement.element_section === 'digital_channels') {
          segmentRule = this.queryRules.find(
            qr => qr.label === 'Digital Channels'
          )
          operandRule = segmentRule.children.find(
            sr => sr.element_id === queryElement.element_id
          )
          elementInfo = {
            ruleId: segmentRule.id,
            parent: operandRule.label,
            service: segmentRule.label,
            operand: operandRule.element_id,
            operandLabel: operandRule.label,
            childOperand: null,
            source: operandRule.source,
            cuSource: operandRule.cu_source,
            ruleType: operandRule.type,
            section: queryElement.element_section,
            options: this.getRuleOptions(
              operandRule.operators.find(
                operator =>
                  operator.label === this.segment.set.element.criteria.operator
              )
            )
          }
        } else {
          technographicRules = this.queryRules.filter(qr =>
            [
              'Retail Services',
              'Business Services',
              'Operating Solutions'
            ].includes(qr.label)
          )

          technographicRules.some(sr => {
            return sr.children.some(or => {
              childOperandRule = or.children.find(
                cor => cor.element_id == queryElement.element_id
              )
              if (childOperandRule) {
                operandRule = or
                segmentRule = sr
              }
              return childOperandRule
            })
          })

          elementInfo = {
            ruleId: 'technographics',
            parent: segmentRule.label,
            service: operandRule.label,
            operand: childOperandRule.element_id,
            operandLabel: childOperandRule.label,
            childOperand: childOperandRule.element_id,
            source: childOperandRule.source,
            cuSource: childOperandRule.cu_source,
            ruleType: childOperandRule.type,
            section: null,
            options: this.getRuleOptions(
              childOperandRule.operators.find(
                operator =>
                  operator.label === this.segment.set.element.criteria.operator
              )
            )
          }
        }

        break
      default:
        break
    }

    return elementInfo
  }

  getRuleOptions(rule) {
    let options = rule.options || []
    if (typeof options[0] === 'object') {
      return options.map(option => {
        return { id: option.value, label: option.label }
      })
    } else {
      return options.map(option => {
        return { id: option, label: option }
      })
    }
  }

  buildQuantiles(elementInfo) {
    return this.segment.set_details.map((sd, index) => {
      let quantile = {
        id: index + 1,
        name: sd.name,
        operator: sd.definition.operator,
        quantileType: elementInfo.ruleType,
        values:
          elementInfo.ruleType === 'select'
            ? elementInfo.options.filter(option =>
                sd.definition.value.includes(option.id)
              )
            : sd.definition.value,
        count: sd.definition.count,
        loading: false,
        options: elementInfo.ruleType === 'select' ? elementInfo.options : null
      }

      if (sd.definition.operator === 'less or equal') {
        quantile.greater_than = 'Minimum'
        quantile.less_or_equal_to = sd.definition.value[0]
      } else if (sd.definition.operator === 'greater') {
        quantile.greater_than = sd.definition.value[0]
        quantile.less_or_equal_to = 'Max'
      } else if (sd.definition.operator === 'between') {
        quantile.greater_than = sd.definition.value[0]
        quantile.less_or_equal_to = sd.definition.value[1]
      }
      return quantile
    })
  }

  buildSegmentRule() {
    let elementInfo = this.getElementInfo()
    return {
      id: this.segment.set.id,
      ruleId: elementInfo.ruleId,
      operand: elementInfo.operand,
      operandLabel: elementInfo.operandLabel,
      //parent: elementInfo.parent,
      //service: elementInfo.service,
      childOperand: elementInfo.childOperand,
      source: elementInfo.source,
      operator: this.segment.set.element.criteria.operator,
      value: this.segment.set.element.criteria.value,
      section: elementInfo.section,
      error: false,
      loading: false,
      segmentType: 'manual',
      quantiles: this.buildQuantiles(elementInfo)
    }
  }

  get retransform() {
    return this.buildSegmentRule()
  }
}

export default SegmentRuleRetransformer
