<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="rule-block d-flex">
        <div class="align-items-center rule-box">
          <div class="row">
            <div class="col-12">
              <div
                :class="[
                  'p-2',
                  'rule-box-cnt',
                  'form-inline',
                  'std-border',
                  'rounded',
                  'align-items-baseline',
                  validSet ? '' : 'rule-error'
                ]"
              >
                <div class="px-1 mb-1">
                  <b-form-select
                    class="form-control"
                    v-model="query.ruleId"
                    @change="ruleIdChanged"
                    :options="baseOptions"
                  ></b-form-select>
                </div>

                <div class="px-1 mb-1" v-if="query.ruleId">
                  <div
                    @click="showTreeModal"
                    class="metric-select-input form-control rounded"
                    v-if="isTreeSelectionRule"
                  >
                    {{ query.operand ? query.operandLabel : 'Please Select' }}
                    <i
                      class="fa fa-external-link link-text pl-2"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <select
                    class="form-control"
                    v-model="query.operand"
                    @change="setDependants"
                    v-else
                  >
                    <option value="null" selected disabled>
                      Please Select
                    </option>
                    <option
                      v-for="(operand, index) in ruleOperands"
                      :key="index"
                      :value="operand.id"
                      >{{ operand.label }}</option
                    >
                  </select>
                </div>

                <div class="px-1 mb-1" v-if="hasChildOperand()">
                  <select
                    class="form-control"
                    v-model="query.childOperand"
                    @change="setChildDependants"
                  >
                    <option value="null" selected disabled>
                      Please Select
                    </option>
                    <option
                      v-for="(childOperand, index) in ruleChildOperands"
                      :key="index"
                      :value="childOperand.id"
                      >{{ childOperand.label }}</option
                    >
                  </select>
                </div>

                <div class="px-1 mb-1" v-if="showOperator()">
                  <div>
                    <select
                      class="form-control"
                      v-model="query.operator"
                      @change="setOperatorDependants"
                    >
                      <option
                        v-for="(operator, index) in ruleOperators"
                        :key="index"
                        :value="operator.id"
                        >{{ operator.label }}</option
                      >
                    </select>
                  </div>
                </div>

                <div class="px-1 mb-1" v-if="this.query.operator">
                  <div class="">
                    <input
                      min="2"
                      max="10"
                      type="number"
                      v-model="query.value"
                      :class="[
                        query.value &&
                        !Array.isArray(query.value) &&
                        !validQuantileCount
                          ? 'general-field-error'
                          : '',
                        'form-control rounded quantile-count'
                      ]"
                      pattern="[0-9.]+"
                      placeholder="Enter # of Segments"
                      @blur="checkQuantiles"
                    />
                  </div>
                  <small
                    class="error-msg"
                    v-if="
                      query.value &&
                        !Array.isArray(query.value) &&
                        !validQuantileCount
                    "
                    >valid range is between 2 and 10</small
                  >
                </div>

                <div class="px-1 mb-1" v-if="this.query.operator">
                  <div class="d-flex">
                    <b-button
                      type="submit"
                      pill
                      size="sm"
                      :class="[
                        validQuantileCount ? '' : 'tag-disabled',
                        'float-right btn px-3 ml-2'
                      ]"
                      @click.stop="setQuantiles"
                      :disabled="!validQuantileCount"
                      variant="dark"
                    >
                      Set
                    </b-button>
                  </div>
                </div>

                <div class="row w-100 pt-1 pb-2 px-2" v-if="totalQuantile">
                  <div class="col-sm-12">
                    <segment-quantile
                      :segmentRule="query"
                      :quantileType="
                        ruleForOptionType && ruleForOptionType.type
                      "
                      :totalQuantile="totalQuantile"
                      :quantileOptions="ruleOptions"
                      :isEditMode="isEditMode"
                      ref="segmentQuantile"
                    ></segment-quantile>
                  </div>
                </div>
              </div>

              <div v-if="!validSet" class="error-msg">
                <small>
                  segment invalid, please fill missing fields
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <common-metric-tree-modal
      queryID=""
      :selectedMetricID="query.operand"
      :segmentableElements="segmentableCommonMetricElements"
      @updateMetric="commonMetricUpdate"
      @updateFIType="fiTypeUpdate"
    ></common-metric-tree-modal>

    <metric-tree-modal
      queryID=""
      :fiType="query.fiType || 'bank'"
      :hideFiTypeSelector="hideFiTypeSelector"
      :selectedMetricID="query.operand"
      :segmentableElements="segmentableMetricElements"
      @updateMetric="metricUpdate"
      @updateFIType="fiTypeUpdate"
    ></metric-tree-modal>

    <statements-modal
      queryID=""
      :fiType="this.fiType || 'both'"
      :hideFiTypeSelector="hideFiTypeSelector"
      :selectedStatementID="query.operand"
      :segmentableElements="segmentableStatementElements"
      @updateStatement="statementUpdate"
      @updateFIType="fiTypeUpdate"
    ></statements-modal>

    <technographics-modal
      queryID=""
      :selectedServiceID="query.operand"
      :segmentableElements="segmentableTechnographicElements"
      @updateService="serviceUpdate"
    ></technographics-modal>
  </div>
</template>

<script>
// global
import { mapState, mapGetters, mapMutations } from 'vuex'
// ui components
import SegmentQuantile from './SegmentQuantile'
import MetricTreeModal from '@/modules/core/components/MetricTreeModal.vue'
import CommonMetricTreeModal from '@/modules/core/components/CommonMetricTreeModal.vue'
import StatementsModal from '@/modules/core/components/StatementsModal.vue'
import TechnographicsModal from '@/modules/core/components/TechnographicsModal.vue'
// general utilities
import { findDeep } from 'deepdash'
export default {
  name: 'Segment',
  components: {
    SegmentQuantile,
    MetricTreeModal,
    CommonMetricTreeModal,
    StatementsModal,
    TechnographicsModal
  },
  props: {
    rules: {
      type: Array
    },
    query: {
      type: Object
    },
    isEditMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hideFiTypeSelector: true,
      fiType: null,
      basicOperators: [
        'equal',
        'less',
        'less or equal',
        'greater',
        'greater or equal',
        'between',
        'not between'
      ]
    }
  },
  computed: {
    ...mapState('IndustryReporter', {
      validSet: state => state.validSet
    }),
    ...mapGetters('IndustryReporter', {
      baseOptions: 'baseSegmentOptions',
      technographicRules: 'technographicRules'
    }),
    //Not used in component - to be removed
    segmentableCommonMetricElements() {
      let commonMetricRules = this.rules.filter(r =>
        ['Profit', 'Growth', 'Risk'].includes(r.label)
      )
      return commonMetricRules
        .map(r => r.children.map(c => c.element_id))
        .flat()
    },
    //Not used in component - to be removed
    segmentableMetricElements() {
      let commonMetricRules = this.rules.filter(r =>
        [
          'Bank - Profit',
          'Bank - Growth',
          'Bank - Risk',
          'CU - Profit',
          'CU - Growth',
          'CU - Risk'
        ].includes(r.label)
      )
      return commonMetricRules
        .map(r => r.children.map(c => c.element_id))
        .flat()
    },
    //Not used in component - to be removed
    segmentableStatementElements() {
      let commonMetricRules = this.rules.filter(r =>
        [
          'Balance Sheet',
          'Income Statement',
          'Deposit Details',
          'Credit Quality',
          'Bank - Balance Sheet',
          'Bank - Income Statement',
          'Bank - Deposit Details',
          'Bank - Credit Quality',
          'CU - Balance Sheet',
          'CU - Income Statement',
          'CU - Deposit Details',
          'CU - Credit Quality'
        ].includes(r.label)
      )
      let arr = commonMetricRules
        .map(r => {
          if (
            [
              'Income Statement',
              'Bank - Income Statement',
              'CU - Income Statement'
            ].includes(r.label)
          ) {
            return r.children.map(c => c.element_id)
          } else {
            return r.children.map(c => c.children.map(gc => gc.element_id))
          }
        })
        .flat(Infinity)
      return arr
    },
    segmentableTechnographicElements() {
      let commonMetricRules = this.rules.filter(r =>
        [
          'Retail Services',
          'Business Services',
          'Operating Solutions'
        ].includes(r.label)
      )
      return commonMetricRules
        .map(r => r.children.map(c => c.children.map(gc => gc.element_id)))
        .flat(Infinity)
    },
    basicOperandRule() {
      return {
        operators: this.basicOperators,
        children: [],
        type: 'number'
      }
    },
    isBasicOperandRule() {
      return [
        'Detailed Metrics',
        'Common Metrics (Bank/CU)',
        'Detailed Statements',
        'Common Statements (Bank/CU)'
      ].includes(this.chosenRule.label)
    },
    isTechnographicsRule() {
      return this.chosenRule.label === 'Technographics'
    },
    isTreeSelectionRule() {
      return this.isBasicOperandRule || this.isTechnographicsRule
    },
    chosenRule() {
      if (!this.query.ruleId) {
        return null
      }
      return this.rules.find(rule => rule.id === this.query.ruleId)
    },
    ruleOperands() {
      if (!this.chosenRule) {
        return []
      }
      return this.chosenRule.children.map(rule => {
        return { id: rule.id, label: rule.label }
      })
    },
    chosenOperandRule() {
      if (!this.query.operand) {
        return null
      }
      if (this.isTreeSelectionRule) {
        return this.isTechnographicsRule
          ? this.matchingTechnographicOperandRule
          : this.basicOperandRule
      } else {
        return this.chosenRule.children.find(
          operand => operand.id === this.query.operand
        )
      }
    },
    matchingTechnographicOperandRule() {
      let matchingService = null

      this.technographicRules.every(rule => {
        matchingService = findDeep(
          rule.children,
          function(bs) {
            return parseInt(bs.element_id) === parseInt(this.query.operand)
          }.bind(this),
          {
            childrenPath: 'children'
          }
        )
        return matchingService ? false : true
      })

      return matchingService ? matchingService.value : {}
    },
    ruleChildOperands() {
      if (!this.hasChildOperand()) {
        return []
      }
      return this.chosenOperandRule.children.map(operand => {
        return { id: operand.id, label: operand.label }
      })
    },
    chosenChildOperandRule() {
      if (!this.query.childOperand) {
        return null
      }
      return this.chosenOperandRule.children.find(
        childOperand => childOperand.id === this.query.childOperand
      )
    },
    ruleOperators() {
      if (!this.hasOperand) {
        return []
      }
      let operandRule = this.hasChildOperand()
        ? this.chosenChildOperandRule
        : this.chosenOperandRule

      if (operandRule) {
        let operators = operandRule.operators
        if (this.isNestedOperator(operators)) {
          return operators.map(operator => {
            return { id: operator.label, label: operator.label }
          })
        } else {
          return operators.map(operator => {
            return { id: operator, label: operator }
          })
        }
      } else {
        return []
      }
    },
    chosenOperatorRule() {
      if (!this.query.operator) {
        return null
      }
      let operandRule = this.hasChildOperand()
        ? this.chosenChildOperandRule
        : this.chosenOperandRule
      let operators = operandRule.operators
      if (this.isNestedOperator(operators)) {
        return operators.find(
          operator => operator.label === this.query.operator
        )
      } else {
        return this.chosenOperandRule
      }
    },
    multiselectRuleOptions() {
      return this.ruleOptions.map(option => option.id)
    },
    ruleOptions() {
      if (!this.query.operator) {
        return []
      }
      // array is temporary, needs to replaced with proper input field
      let options = this.chosenOperatorRule.options || []
      if (typeof options[0] === 'object') {
        return options.map(option => {
          return { id: option.value, label: option.label }
        })
      } else {
        return options.map(option => {
          return { id: option, label: option }
        })
      }
    },
    hasOperand() {
      return this.hasChildOperand()
        ? this.query.childOperand
        : this.query.operand
    },
    ruleForOptionType() {
      return this.hasChildOperand()
        ? this.chosenChildOperandRule
        : this.chosenOperandRule
    },
    totalQuantile() {
      // TODO Need to check this issue
      return (
        (this.query && this.query.quantiles && this.query.quantiles.length) || 0
      )
    },
    validQuantileCount() {
      let total = parseInt(this.query.value)
      return total > 1 && total < 11
    },
    isFinancialStatement() {
      if (this.hasChildOperand()) {
        return (
          this.chosenChildOperandRule &&
          ['financial_statements', 'common_financial_statements'].includes(
            this.chosenChildOperandRule.element_type
          )
        )
      } else if (this.hasOperand) {
        return (
          this.chosenOperandRule &&
          ['financial_statements', 'common_financial_statements'].includes(
            this.chosenOperandRule.element_type
          )
        )
      }
      return false
    }
  },
  methods: {
    ...mapMutations('IndustryReporter', [
      'setSegmentSetValidity',
      'setSegmentSetDetailsValidity'
    ]),
    getFiType() {
      return this.query.fiType
    },
    showTreeModal() {
      switch (this.chosenRule.label) {
        case 'Detailed Metrics':
          this.query.fiType = this.query.fiType || 'bank'
          this.$bvModal.show(`metricTreeModal-`)
          break
        case 'Common Metrics (Bank/CU)':
          this.query.fiType = 'both'
          this.$bvModal.show(`commonMetricTreeModal-`)
          break
        case 'Detailed Statements':
          this.fiType = this.query.fiType || 'bank'
          this.query.fiType = this.query.fiType || 'bank'
          this.$bvModal.show(`commonStatementsModal-`)
          break
        case 'Common Statements (Bank/CU)':
          this.fiType = 'both'
          this.query.fiType = 'both'
          this.$bvModal.show(`commonStatementsModal-`)
          break
        case 'Technographics':
          this.$bvModal.show(`technographicsModal-`)
          break
        default:
          break
      }
    },
    hasChildOperand() {
      return (
        this.chosenOperandRule && this.chosenOperandRule.children.length > 0
      )
    },
    isNestedOperator(operators) {
      return operators.length > 0 && typeof operators[0] === 'object'
    },
    showOperator() {
      if (this.chosenRule && this.isTechnographicsRule && this.query.operand) {
        return true
      } else if (this.isFinancialStatement) {
        return false
      } else if (this.hasChildOperand() && this.chosenChildOperandRule) {
        return true
      } else if (
        this.chosenRule &&
        this.chosenOperatorRule &&
        this.chosenRule.label === 'Digital Channels'
      ) {
        return this.chosenOperandRule.source.match('bank_services.id')
      }

      return false
    },
    ruleIdChanged() {
      this.query.operand = null
      this.query.childOperand = null
      this.query.operator = null
      this.query.value = null
      this.query.quantiles = []
      //needed?
      this.query.source = null
      this.query.cuSource = null
      this.query.operandLabel = null
    },
    setDependants() {
      this.query.childOperand = null
      this.query.operator = null
      this.query.value = null
      this.query.quantiles = []

      if (this.hasChildOperand()) {
        this.query.source = null
      } else {
        this.query.operator = this.ruleOperators.length
          ? this.ruleOperators[0].id
          : null
        this.query.source = this.chosenOperandRule.source
      }
    },
    setChildDependants() {
      this.query.operator = null
      this.query.value = null
      this.query.quantiles = []
      if (this.chosenChildOperandRule) {
        this.query.operator = this.ruleOperators[0].id
        this.query.source = this.chosenChildOperandRule.source
      }
    },
    setOperatorDependants() {
      this.query.value = null
      this.query.quantiles = []
    },
    setQuantiles() {
      if (
        this.query.value &&
        parseInt(this.query.value) !== this.totalQuantile
      ) {
        this.resetValidations()
        this.query.quantiles = []
        let quantileCount = parseInt(this.query.value)
        this.$nextTick(() => {
          this.query.quantiles = [...Array(quantileCount)]
        })
      }
    },
    checkQuantiles() {
      if (!this.query.value) {
        this.query.quantiles = []
      }
    },
    checkSetValidity() {
      this.setSegmentSetValidity(this.query.value ? true : false)
      if (this.totalQuantile) {
        this.$refs.segmentQuantile.checkQuantileRuleValidity()
      } else {
        this.setSegmentSetDetailsValidity(false)
      }
    },
    resetValidations() {
      this.setSegmentSetValidity(true)
      this.setSegmentSetDetailsValidity(true)
    },
    resetOperandDependants() {
      this.query.fitType = null
      this.query.childOperand = null
      this.query.operator = null
      this.query.count = null
      this.query.value = []
      this.query.quantiles = []
    },
    serviceUpdate(node) {
      this.resetOperandDependants()
      this.query.childOperand = node.data.id
      this.query.operand = node.data.id //serviceId
      this.query.service = node.service
      this.query.operandLabel = node.text
      this.query.parent = node.parentName
      this.query.operator = this.ruleOperators[0].id
      this.query.source = this.chosenOperandRule.source
      this.query.cuSource = this.chosenOperandRule.cu_source
    },
    statementUpdate(node) {
      this.resetOperandDependants()
      this.query.childOperand = node.data.element_id
      this.query.operand = node.data.element_id
      this.query.operandLabel = node.text
      this.query.parent = node.parentName
      this.query.service = node.service
      this.query.operator = this.ruleOperators[0].id
      this.query.source = this.chosenOperandRule.source
      this.query.cuSource = this.chosenOperandRule.cu_source
    },
    metricUpdate(node) {
      this.resetOperandDependants()

      this.query.operand = node.data.metric_id
      this.query.operandLabel = node.data.text
      this.query.parent = node.parentName
      this.query.operator = this.ruleOperators[0].id
      this.query.source = this.chosenOperandRule.source
      this.query.cuSource = this.chosenOperandRule.cu_source
    },
    commonMetricUpdate(node) {
      this.resetOperandDependants()

      this.query.operand = node.data.id
      this.query.operandLabel = node.data.text
      this.query.parent = node.parentName
      this.query.operator = this.ruleOperators[0].id
      this.query.source = this.chosenOperandRule.source
      this.query.cuSource = this.chosenOperandRule.cu_source
    },
    fiTypeUpdate(fiType) {
      this.query.fiType = fiType
      this.fiType = fiType
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.rule-box-cnt {
  background-color: #f5f5f5;
}

.close-icon {
  margin-left: auto;
}

.rule-block,
.rule-box {
  width: 100%;
}

.rule-operator {
  margin-right: 0.5rem;
  min-width: 2rem;
  font-weight: bold;
}

.rule-count {
  margin-left: 0.5rem;
  min-width: 3.2rem;
  font-weight: bold;
}

.rule-error {
  border: solid 1px #dc3545;
  border-left: solid 1px #dc3545 !important;
  background: #fae3e3;
}

.clone-rule-icon {
  font-size: 1rem;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.quantile-count {
  min-width: 11rem;
}
</style>

<style lang="scss">
.advanced-query-select {
  min-height: 33px;

  .multiselect__select {
    height: 33px;
  }

  .multiselect__tags {
    min-height: 33px;
    padding-top: 0.25rem;

    .multiselect__placeholder {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
